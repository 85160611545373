import React, { useState } from 'react';
import headerLogo from "../../Assets/images/header-logo.png";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../api/getApiURL';
import { toast } from 'react-toastify';

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        user_wallet: '-',
        role: 'user'
    });
    
      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        }));
      };

      const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${API_BASE_URL}/users/signup`, formData);
            toast.success("User registered successfully!");
            navigate("/login");
        } catch (error) {
            toast.error(error.response.data.error);
            console.error('There was an error!', error);
        }
    };
    
    return (
        <div>
            <div className="user-panel">
                <div className="top-wrapper">
                <div className="top_container">
          
                <h1 className="title mt-10">{"TrustPro"}</h1>
                <div className="an_title">
                    <div className="info">
                    <div className="desc">
                        <p>Your one-stop solution for trading needs.</p>
                    </div>
                    </div>
                    <img src={headerLogo} className="shap" alt="Header Logo" />
                </div>
                </div>
                </div>
                <section className="">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                        <div className="w-full bg-gray-100 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-100 dark:border-gray-200">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-gray-900">
                            Create a new account
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                Full Name
                                </label>
                                <input
                                type="text"
                                name="name"
                                id="name"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Enter your full name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                Your email
                                </label>
                                <input
                                type="email"
                                name="email"
                                id="email"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="name@company.com"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                />
                            </div>
                            <div>
                                <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                Mobile
                                </label>
                                <input
                                type="mobile"
                                name="mobile"
                                id="mobile"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="your mobile number"
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                                Password
                                </label>
                                <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="••••••••"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                />
                            </div>
                            {/* <div className="flex items-center justify-between">
                                <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input
                                    id="remember"
                                    name="remember"
                                    aria-describedby="remember"
                                    type="checkbox"
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                    checked={formData.remember}
                                    onChange={handleChange}
                                    />
                                </div>
                                 <div className="ml-3 text-sm">
                                    <label htmlFor="remember" className="text-gray-500 dark:text-gray-500">
                                    Remember me
                                    </label>
                                </div> 
                                </div>
                                 <a href="#" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">
                                Forgot password?
                                </a>
                            </div> */}
                            <button
                                type="submit"
                                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                Sign Up
                            </button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-500">
                                Already have an account ?{' '}
                                <Link to="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                                Login here
                                </Link>
                            </p>
                            </form>
                        </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Register;